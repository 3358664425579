import React from 'react'
import { ScreenSubMenuTP } from 'main/common/components/screen-layout-submenu/submenu/inner/ScreenSubMenuItemTP'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { ReportsViewTP } from 'main/modules/reports/screens/screen-reports/inner/ReportsViewTP'
import { ReportsRoutes } from 'main/modules/reports/routes/ReportsRoutes'
import { ScreenContentSaleSourceReportCP } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-sale-source-report/ScreenContentSaleSourceReportCP'
import { ScreenContentFunnelIndicatorsReportCP } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-funnel-indicators-report/ScreenContentFunnelIndicatorsReportCP'
import { FinancialSalesReportCP } from 'main/modules/financial/components/financial-sales-report/FinancialSalesReportCP'
import { ScreenContentSchedulerListReportCP } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-scheduler-list-report/ScreenContentSchedulerListReportCP'
import { ScreenContentFinancialReportCP } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-financial-report/ScreenContentFinancialReportCP'
import { ScreenContentNpsReportCP } from 'main/modules/reports/screens/screen-reports/screen-content/screen-content-nps-report/ScreenContentNpsReportCP'
import { RelatorioComissaoTela } from '../screen-content/screen-comissao-report/RelatorioComissaoTela'
import { RelatorioSessoesTela } from '../screen-content/screen-sessoes-restantes/RelatorioSessoesTela'
import { RelatorioTratamento } from '../screen-content/screen-comissao-report/RelatorioTratamento'

export const SALES_REPORTS_SUBMENU_KEY = 'sales'
export const INDICATORS_REPORTS_SUBMENU_KEY = 'indicators'
export const SCHEDULERS_REPORTS_SUBMENU_KEY = 'schedulers'
export const COMISSOES_KEY = 'comissoes'
export const FINANCIAL_REPORTS_SUBMENU_KEY = 'financials'

/**
 * UTILS
 * Reune metodos auxiliares para gestao da tela de Relatorios.
 */
export const ReportsScreenUtils = {

    getSubMenuConfigs(): Array<ScreenSubMenuTP<ReportsViewTP>> {
        return [
            {
                key: INDICATORS_REPORTS_SUBMENU_KEY,
                title: 'Indicadores',
                items: [
                    {
                        name: 'Pesquisa de Satisfação (NPS)',
                        view: 'npsReportView',
                        route: ReportsRoutes.NPS_REPORT,
                        content: <ScreenContentNpsReportCP/>
                    },
                    {
                        name: 'Pipeline de Vendas',
                        view: 'funnelIndicatorsReportView',
                        route: ReportsRoutes.FUNNEL_INDICATOR_REPORT,
                        content: <ScreenContentFunnelIndicatorsReportCP/>,
                        enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_FINANCIAL])
                    },
                    
                ],
                enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_CRM])
            },
            {
                key: SALES_REPORTS_SUBMENU_KEY,
                title: 'Vendas',
                items: [
                    {
                        name: 'Análise de Vendas',
                        view: 'reportSaleDetailsView',
                        contentTitle: 'Análise de Vendas',
                        route: ReportsRoutes.SALE_ANALYSIS_REPORT,
                        content: <FinancialSalesReportCP/>,
                        enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_FINANCIAL])
                    },
                    {
                        name: 'Origem das Vendas',
                        view: 'saleSourceReportView',
                        route: ReportsRoutes.SALE_SOURCE_REPORT,
                        contentTitle: 'Relatório de Origem das Vendas',
                        content: <ScreenContentSaleSourceReportCP/>,
                        enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_FINANCIAL])
                    },
                    {
                        name: 'Comissões Por Agente',
                        view: 'comissoesView',
                        route: ReportsRoutes.COMISSOES_REPORT,
                        contentTitle: 'Relatório das Comissões por Agente e Período',
                        content: <RelatorioComissaoTela/>,// inserir componente aqui
                        enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_FINANCIAL])
                    },
                    {
                        name: 'Vendas Por Tratamento',
                        view: 'vendasTratamentoView',
                        route: ReportsRoutes.VENDAS_POR_TRATAMENTO,
                        contentTitle: 'Relatório Quantidade de Vendas por tipo de tratamento.',
                        content: <RelatorioTratamento/>,// inserir componente aqui
                        enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_FINANCIAL])
                    },
                    {
                        name: 'Sessões Restantes',
                        view: 'sessoesRestView',
                        route: ReportsRoutes.SESSOES_RESP_REPORT,
                        contentTitle: 'Relação de Clientes com sessões próximas do fim',
                        content: <RelatorioSessoesTela/>// inserir componente aqui
                    },
                ],
                enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_CRM])
            },
            {
                key: FINANCIAL_REPORTS_SUBMENU_KEY,
                title: 'Financeiro',
                items: [
                    {
                        name: 'DRE',
                        view: 'dreReportView',
                        route: ReportsRoutes.FINANCIAL_DRE_REPORT,
                        content: <ScreenContentFinancialReportCP/>
                    },
                ],
                enabled: PermissionUtils.arePermissionsGranted([PermissionEnum.ROLE_FINANCIAL])
            },
            {
                key: SCHEDULERS_REPORTS_SUBMENU_KEY,
                title: 'Agenda',
                items: [
                    {
                        name: 'Agenda do Dia',
                        view: 'schedulerListReportView',
                        route: ReportsRoutes.SCHEDULER_LIST_REPORT,
                        content: <ScreenContentSchedulerListReportCP/>
                    },
                ],
                enabled: true
            },
            
        ]
    },

}

