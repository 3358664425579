import React, { useEffect, useState } from 'react'
import { ModalCP } from 'main/common/components/modal/ModalCP'
import { FallbackScreenContentCP } from 'main/common/components/fallback-screen-content/FallbackScreenContent'
import { RadioGroupCP } from 'main/common/components/form-fields/radio-group/RadioGroupCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { AutocompleteTagCP } from 'main/modules/admin/components/autocomplete-tag/AutocompleteTagCP'
import styled from 'main/config/theme/styledWithTheme'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import { OpportunityRequests } from 'main/modules/sales-funnel/services/opportunity/OpportunityRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { EvaluationConfirmFormModel } from 'main/modules/sales-funnel/components/modal-evaluation-confirm/inner/EvaluationConfirmFormModel'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { DrawerCreateSaleCP } from 'main/modules/sale/components/drawer-create-sale/DrawerCreateSaleCP'
import { IOpportunitySaveRequestDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/request/IOpportunitySaveRequestDTO'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { AutoCompleteCancelationReasonCP } from 'main/modules/sales-funnel/components/autocomplete-cancelation-reason/AutoCompleteCancelationReasonCP'
import { CancelationReasonTypeEnum } from 'main/modules/sales-funnel/services/cancelation-reason/enums/CancelationReasonTypeEnum'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { SaleTypeEnum } from 'main/modules/sale/enums/SaleTypeEnum'

export type EvaluationDoneActionTP = 'doSale' | 'goToFollowUp' | 'archive' | 'doSaleQuote'

interface ICPProps {
    visible: boolean
    opportunityCode: number
    onSave: (action: EvaluationDoneActionTP) => void
    onCancel: () => void
}

/**
 * Modal de geracao de contrato para uma venda
 */
export function ModalEvaluationConfirmCP(props: ICPProps): JSX.Element {

    const [formModelValidator, setFormModelValidator] = useState<EvaluationConfirmFormModel>(new EvaluationConfirmFormModel())
    const formStateManager = useFormStateManager<EvaluationConfirmFormModel>(formModelValidator)

    const [isCreateSaleDrawerVisible, setIsCreateSaleDrawerVisible] = useState<boolean>(false)

    const [opportunity, setOpportunity] = useState<IOpportunityResponseDTO>()
    const getOpportunityRequest = useRequest<IOpportunityResponseDTO>()
    useEffect(onGetOpportunityRequestChange, [getOpportunityRequest.isAwaiting])

    const opportunitySaveRequest = useRequest<IOpportunityResponseDTO>()
    useEffect(onOpportunitySaveRequestChange, [opportunitySaveRequest.isAwaiting])

    const archiveOpportunityRequest = useRequest<'void'>('none')
    useEffect(archiveOpportunityRequestChange, [archiveOpportunityRequest.isAwaiting])

    const changeToFollowupRequest = useRequest<string>()
    useEffect(changeToFollowupRequestChange, [changeToFollowupRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     * Inicializa os dados da tela
     */
    function init(): void {

        setFormModelValidator(new EvaluationConfirmFormModel())
        if (!props.visible)
            return

        getOpportunityRequest.runRequest(OpportunityRequests.getOpportuityConfig(props.opportunityCode))
    }

    /**
     * Retorno da api com os dados da oportunidade.
     */
    function onGetOpportunityRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getOpportunityRequest, 'Erro ao buscar os dados da oportunidade.'))
            return

        const opportunityResult = getOpportunityRequest.responseData!
        setFormModelValidator(new EvaluationConfirmFormModel({
            tagCodes: opportunityResult.tags?.map((tag) => tag.code)
        }))
        setOpportunity(opportunityResult)
    }

    /**
     * Primeiro Atualiza a oportunidade com as tags informadas.
     */
    async function onSave(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        const formValues = formStateManager.getFormValues()
        if (!await formStateManager.validate() || !formValues || !opportunity)
            return

        // Atualiza com os mesmos dados informados, acrescentando apenas as tags
        const dto: IOpportunitySaveRequestDTO = {
            tags: formValues.tagCodes, // Unica informacao atualizada
            leadName: opportunity.leadData.name,
            leadEmail: opportunity.leadData.email,
            leadPhone: opportunity.leadData.phone,
            note: opportunity.note,
            userResponsibleCode: opportunity.userResponsible.code,
            sourceCode: opportunity.source.code,
            sourcePersonLinkCode: opportunity.sourcePersonLink?.code,
            personCustomerCode: opportunity.personCustomer?.code
        }

        opportunitySaveRequest.runRequest(OpportunityRequests.updateConfig(opportunity.code, dto))
    }

    /**
     * Retorno ao salvar oportunidade
     */
    function onOpportunitySaveRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(opportunitySaveRequest, 'Erro ao atualizar tags.'))
            return

        if (formStateManager.getFieldValue('actionType') === 'doSale')
            setIsCreateSaleDrawerVisible(true)

        else if (formStateManager.getFieldValue('actionType') === 'doSaleQuote')
            setIsCreateSaleDrawerVisible(true)

        else if (formStateManager.getFieldValue('actionType') === 'archive')
            archiveOpportunity()

        else if (formStateManager.getFieldValue('actionType') === 'goToFollowUp')
            moveOpportunityToFollowup()
    }

    /**
     * Arquiva uma oportunidade.
     */
    function archiveOpportunity(): void {
        archiveOpportunityRequest.runRequest(OpportunityRequests.markOpportunityAsLost(opportunity!.code, formStateManager.getFieldValue('archiveReasonCode')))
    }

    /**
     * Retorno da api ao arquivar uma oportunidade.
     */
    function archiveOpportunityRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(archiveOpportunityRequest, 'Erro ao tentar arquivar oportunidade.'))
            return

        NotificationHelper.success('Pronto!', 'Oportunidade arquivada com sucesso')
        props.onSave('archive')
    }

    /**
     * Muda oportunidade para followup.
     */
    function moveOpportunityToFollowup(): void {
        changeToFollowupRequest.runRequest(OpportunityRequests.changeToFollowUp(props.opportunityCode))
    }

    /**
     * Retorno da api ao mudar uma oportunidade para followup.
     */
    function changeToFollowupRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(changeToFollowupRequest, 'Erro ao tentar arquivar oportunidade.'))
            return

        NotificationHelper.success('Pronto!', 'Oportunidade já está no funil de followup')
        props.onSave('goToFollowUp')
    }

    return (
        <>
            <ModalCP
                title={'Avaliação Realizada, como deseja prosseguir?'}
                visible={isCreateSaleDrawerVisible ? false : props.visible}
                onCancel={props.onCancel}
                top={10}
                footer={
                    <FlexCP justifyContent={'flex-end'}>
                        <ButtonCP
                            type={'primary'}
                            onClick={onSave}
                            loading={opportunitySaveRequest.isAwaiting ?? archiveOpportunityRequest.isAwaiting ?? changeToFollowupRequest.isAwaiting}
                        >
                            Prosseguir
                        </ButtonCP>
                    </FlexCP>
                }
                width={690}
                loading={getOpportunityRequest.isAwaiting}
            >
                <WrapperSCP>
                    <FallbackScreenContentCP
                        status={'success'}
                        message={'Escolha uma das opções abaixo para encaminharmos o LEAD para a próxima etapa:'}
                        bottomContent={
                            <>
                                <RadioGroupCP<EvaluationDoneActionTP>
                                    formStateManager={formStateManager}
                                    fieldName={'actionType'}
                                    buttonHeight={40}
                                    paddingTop={0}
                                    secondary={true}
                                    type={'button'}
                                    options={[
                                        {
                                            value: 'doSale',
                                            content: <TextCP text={'Fazer a Venda'} icon={'shopping-cart'}/>,
                                            tooltip: 'Cliente comprou, desejo prosseguir para fechar a venda',
                                        },
                                        {
                                            value: 'doSaleQuote',
                                            content: <TextCP text={'Criar orçamento'} icon={'reconciliation'}/>,
                                            tooltip: 'Cliente não irá comprar agora, desejo criar um orçamento',
                                        },
                                        {
                                            value: 'goToFollowUp',
                                            content: <TextCP text={'Mover para follow-up'} icon={'funnel-plot'}/>,
                                            tooltip: 'Cliente optou por não comprar neste momento, desejo mover para followup para entrar em contato novamente',
                                        },
                                        {
                                            value: 'archive',
                                            content: <TextCP text={'Aquivar'} icon={'dislike'}/>,
                                            tooltip: 'Cliente não comprou e não tem interesse algum em comprar no futuro',
                                        },
                                    ]}
                                />

                                <ConditionalRenderCP shouldRender={!!formStateManager.getFieldValue('actionType')}>
                                    <TagsWrapperSCP>
                                        <AutocompleteTagCP
                                            label={'Selecione as Áreas/Serviços de Interesse'}
                                            required={AppStateUtils.getCurrentFranchise()?.paramsConfig?.fieldsConfig?.opportunity?.isTagRequiredOnEvaluationDone}
                                            formStateManager={formStateManager}
                                            fieldName={'tagCodes'}
                                        />
                                    </TagsWrapperSCP>
                                </ConditionalRenderCP>

                                <ConditionalRenderCP shouldRender={formStateManager.getFieldValue('actionType') === 'archive'}>
                                    <AutoCompleteCancelationReasonCP
                                        type={CancelationReasonTypeEnum.OPPORTUNITY}
                                        formStateManager={formStateManager}
                                        fieldName={'archiveReasonCode'}
                                        label={'Qual o motivo para arquivar a oportunidade?'}
                                    />
                                </ConditionalRenderCP>
                            </>
                        }
                    />
                </WrapperSCP>
            </ModalCP>

            <DrawerCreateSaleCP
                visible={isCreateSaleDrawerVisible}
                type={formStateManager.getFieldValue('actionType') === 'doSaleQuote' ? SaleTypeEnum.SALE_QUOTE : SaleTypeEnum.SALE}
                opportunityCode={props.opportunityCode}
                closeDrawer={() => setIsCreateSaleDrawerVisible(false)}
                onSave={() => {
                    setIsCreateSaleDrawerVisible(false)
                    props.onSave('doSale')
                }}
            />
        </>
    )

}

const TagsWrapperSCP = styled.div`
  margin-top: 30px;
`

const WrapperSCP = styled.div`
  
  .ant-result {
    padding: 20px !important;
  }
  
  .ant-result-extra {
    text-align: initial !important;
  }
`
