import React, { useEffect, useState } from 'react'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { RequestConfigTP } from 'main/common/request-manager/types/RequestConfigTP'
import { IAutocompleteCommonProps } from 'main/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { SelectFullOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectFullOptionTP'
import { AutocompletePickerUtils } from 'main/common/components/form-fields/autocomplete-picker/inner/AutocompletePickerUtils'
import { styled } from 'main/config/theme/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import { PlusIconCP } from 'main/common/components/icons/PlusIconCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TransientValueTrimmer } from 'main/common/transient-value-trimmer/TransientValueTrimmer'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { FormModel } from 'main/common/form-state-manager/FormModel'

const PAGINATION_ITEMS_DEFAULT = 20
const PAGINATION_PAGE_DEFAULT = 1

const NOT_FOUND_TXT_START_TYPING = 'Comece a digitar'
const NOT_FOUND_TXT_LOADING = 'Carregando...'
const NOT_FOUND_TXT_EMPTY = 'Nenhum item encontrado'

interface IAutocompletePickerCPProps<
    ListItemTP extends INameAndCodeResponseDTO,
    FModelTP extends FormModel = any

> extends IAutocompleteCommonProps<ListItemTP, FModelTP> {
    label: string
    requestConfigGetter: (searchDto: any) => RequestConfigTP
    customFilters?: any
    loading?: boolean
    tempo?:any
    mustClearSearch?: boolean
    onSearchCleared?: () => void
    onAdding?: (name: string) => void
    filterOption?: boolean
    onLoadOptions?: (loadedOptionsObjects: any[]) => void
}

/**
 * COMPONENTE
 * Seletor com autocomplete generico.
 * NOTE: Caso este componente seja controlado via 'form state manager' seu valor nao eh determinado diretamente pela prop 'value'.
 *
 * @see FormStateManager
 */
export function AutocompletePickerCP<
    ListItemTP extends INameAndCodeResponseDTO,
    FModelTP extends FormModel = any

>(props: IAutocompletePickerCPProps<ListItemTP, FModelTP>): JSX.Element {

    const [searchString, setSearchString] = useState<string>('')
    const [options, setOptions] = useState<SelectFullOptionTP[]>([])
    const [notFoundTxt, setNotFoundTxt] = useState<string>(NOT_FOUND_TXT_START_TYPING)
    const [selectedOptions, setSelectedOptions] = useState<SelectFullOptionTP[]>([])
    const [onChangeSearchStrTrimmer] = useState<TransientValueTrimmer<string>>(new TransientValueTrimmer(AutocompletePickerUtils.getSearchStrTrimmerConfig(setSearchString)))

    const searchRequest = useRequest<IGenericListResponseDTO<ListItemTP>>()

    useEffect(onSearchTxtChange, [searchString, props.showOptionsOnLoad])
    useEffect(onSearchRequestChange, [searchRequest.isAwaiting])
    useEffect(onInitialOptionsReceived, [props.initialOptions])
    useEffect(onMustClearSearchChange, [props.mustClearSearch])

    function obterNumeroDoDiaDaSemana(data:Date) {
        return data.getDay();
    }
    function estaEntreHorarios(data:Date, horarioInicio:string, horarioFim:string) {
        console.log("TESTE HORARIOS ", data, horarioInicio, horarioFim);
        const dataEmMilisegundos = converterHorarioParaMilisegundos(extrairHorarioDaData(data));
        const horarioInicioEmMilisegundos = converterHorarioParaMilisegundos(horarioInicio);
        const horarioFimEmMilisegundos = converterHorarioParaMilisegundos(horarioFim);
        console.log("ELEMENTOS ", dataEmMilisegundos, horarioInicioEmMilisegundos, horarioFimEmMilisegundos);
        return horarioInicioEmMilisegundos <= dataEmMilisegundos && dataEmMilisegundos <= horarioFimEmMilisegundos;
    }
      
    function converterHorarioParaMilisegundos(horario:string) {
    const [horas, minutos] = horario.split(':');
    const horasEmMilisegundos = parseInt(horas) * 3600000;
    const minutosEmMilisegundos = parseInt(minutos) * 60000;
    return horasEmMilisegundos + minutosEmMilisegundos;
    }
    function extrairHorarioDaData(data:Date) {
        const horas = data.getHours().toString().padStart(2, "0");
        const minutos = data.getMinutes().toString().padStart(2, "0");
        return horas + ":" + minutos;
    }

    function onSearchRequestChange(): void {
        console.log("Recebeu ");
        if (!searchRequest.wasTried || searchRequest.isCancelled)
            return

        if (searchRequest.isAwaiting)
            return setNotFoundTxt(NOT_FOUND_TXT_LOADING)
        console.log("PROFISSIONAIS CARREGADOS ", searchRequest.responseData?.list)
        console.log("TEMPO ", props?.tempo)
        if(props?.tempo && searchRequest.responseData?.list){
            const dia = obterNumeroDoDiaDaSemana(props.tempo);
            console.log("O dia é ", dia);
            let filtrada = searchRequest.responseData.list.filter((it:any)=>{
                const li = it.workSchedule.map((i:any)=>i.weekDay);
                return li.includes(dia);
            });

            filtrada = filtrada.filter((it:any)=>{
                const apon = it.workSchedule.filter((wor:any)=>wor.weekDay==dia && wor.type!='UNAVAILABLE');
                console.log("Filtro do apontamento ", apon);
                if(!apon.length)return false;

                return estaEntreHorarios(props.tempo,apon[0].beginTime,apon[0].endTime);
            })

            _setOptions(AutocompletePickerUtils.getSelectOptions(filtrada), true)
        }else{
            const lista = AutocompletePickerUtils.getSelectOptions(searchRequest.responseData?.list ?? []);
            console.log("ENTROU NA HORA DE SETAR...", lista);
            _setOptions(lista, true)
        }

        if (props.onLoadOptions)
            props.onLoadOptions(searchRequest.responseData?.list ?? [])

        if (RequestUtils.isRequestError(searchRequest)) {
            RequestUtils.handleError({
                request: searchRequest,
                errorMsg: 'Falha ao buscar lista para seletor via autocomplete',
                failureLogMsg: `FALHA - ${AutocompletePickerCP.name}.${onSearchRequestChange.name}`,
            })
        }
    }

    function onSearchTxtChange(): void {

        if (!searchString && !props.showOptionsOnLoad) {

            _setOptions([], false)
            searchRequest.cancelRequest()

            if (!!props.onSearchCleared)
                props.onSearchCleared()

            return
        }

        const searchDto = {
            itemsPerPage: props.searchItems ?? PAGINATION_ITEMS_DEFAULT,
            page: props.searchPage ?? PAGINATION_PAGE_DEFAULT,
            searchString,
            ...(props.customFilters || {})
        }

        searchRequest.runRequest(props.requestConfigGetter(searchDto))
    }

    function onInitialOptionsReceived(): void {

        const receivedOptions = AutocompletePickerUtils.getSelectOptions(props.initialOptions ?? [])
        if (!receivedOptions.length || searchRequest.isAwaiting)
            return

        // Atualiza opcoes disponiveis
        const nextOptions = AutocompletePickerUtils.getUniqueOptionsList([
            ...options,
            ...receivedOptions
        ])

        _setOptions(nextOptions, false)

        if (!props.isMultiple)
            return

        // Atualiza dados das opcoes ja selecionadas
        const currentValue = getCurrentMultipleValue()

        const nextSelectedOptions = AutocompletePickerUtils.getUniqueOptionsList([
            ...selectedOptions,
            ...receivedOptions.filter(listItem => currentValue.includes(listItem.value as number)),
        ])

        setSelectedOptions(nextSelectedOptions)
    }

    function onMustClearSearchChange(): void {
        if (!!props.mustClearSearch)
            setSearchString('')
    }

    function _setOptions(_options: SelectFullOptionTP[], hasSearchBeenRan: boolean): void {
        if (!_options.length){
            setNotFoundTxt(hasSearchBeenRan ? NOT_FOUND_TXT_EMPTY : NOT_FOUND_TXT_START_TYPING)
            console.log("Deu algum BO ao setar as opções ",_options);
        }
        setOptions(_options)
    }

    function getCurrentMultipleValue(): number[] {
        const hasFStateManager = (!!props.formStateManager && !!props.fieldName)
        const currentValue = hasFStateManager ? props.formStateManager?.getFieldValue(props.fieldName!) : props.value
        return currentValue ?? []
    }

    function handleChange(value?: any): void {

        if (!!props.isMultiple && !!props.returnFullOption)
            setSelectedOptions(value)

        if (!!props.onChange)
            props.onChange(value)
    }

    const showAddingBtn = (
        !!props.onAdding
        && notFoundTxt === NOT_FOUND_TXT_EMPTY
        && !!searchString
        && !props.loading
    )

    return (
        <WrapperSCP>
            <SelectCP<FModelTP>
                options={options}
                selectedOptions={selectedOptions}
                loading={searchRequest.isAwaiting || !!props.loading}
                onSearch={_searchString => onChangeSearchStrTrimmer.onChange(_searchString)}
                onChange={handleChange}
                onFormSubmit={props.onFormSubmit}
                isMultiple={!!props.isMultiple}
                returnFullOption={props.returnFullOption}
                fieldName={props.fieldName}
                formStateManager={props.formStateManager}
                label={props.label}
                required={props.required}
                width={props.width}
                allowClear={props.allowClear}
                value={props.value}
                fontSize={props.fontSize}
                disabled={props.disabled}
                notFoundContent={notFoundTxt}
                filterOption={props.filterOption}
            />

            {
                showAddingBtn &&
                <ButtonWrapperSCP show={showAddingBtn}>
                    <ButtonCP
                        onClick={() => props.onAdding!(searchString)}
                        type={'ghost'}
                        borderColor={'transparent'}
                        tooltip={'Adicionar'}
                    >
                        <PlusIconCP type={'circle'} color={ThemeAnt.primaryColor} size={15}/>
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    position: relative;
`

const ButtonWrapperSCP = styled.div<{ show: boolean }>`
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 5px;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    transition: opacity .5s;
    opacity: ${props => (props.show ? 1 : 0)};
`
