import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { AlertCP } from 'main/common/components/alert/AlertCP'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { CreateChargeFormModel } from 'main/modules/sale/components/modal-create-charge/inner/CreateChargeFormModel'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { ISaleCharge } from 'main/modules/sale/components/table-sale-charges/inner/ISaleCharge'
import { CreateChargeUtils } from 'main/modules/sale/components/modal-create-charge/inner/CreateChargeUtils'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { IPaymentConfigSearchMostRelevantPartnerResponseDTO } from 'main/modules/financial/services/payment-config/dtos/response/IPaymentConfigSearchMostRelevantPartnerResponseDTO'
import { SelectPaymentConfigCP } from 'main/modules/sale/components/select-payment-config/SelectPaymentConfigCP'
import { PaymentConfigRulesResponseDTO } from 'submodules/neritclin-sdk/services/sale/paymentconfig/dtos/responses/inner/PaymentConfigRulesResponseDTO'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SelectPaymentTypeCP } from 'main/modules/sale/components/select-payment-type/SelectPaymentTypeCP'

interface ICreateChargeFormDataICPProps {
    formStateManager: IFormStateManager<CreateChargeFormModel>
    saleDate: Date
    isEditingSaleCharges: boolean
    totalRemainingValue: number
    onGenerateInstallments: (partnerConfig?: PaymentConfigRulesResponseDTO) => void
    initialCharge?: ISaleCharge
}

/**
 * Dados do formulario de cadastro de uma cobranca.
 */
export function CreateChargeFormDataICP(props: ICreateChargeFormDataICPProps): JSX.Element {

    const [shouldShowFirstInstallmentDate, setShouldShowFirstInstallmentDate] = useState<boolean>(false)
    const [shouldShowTotalInstallments, setShouldShowTotalInstallments] = useState<boolean>(false)
    const [shouldShowHasGracePeriod, setShouldShowHasGracePeriod] = useState<boolean>(false)
    const [shouldShowPaymentType, setShouldShowPaymentType] = useState<boolean>(false)

    const [canGenerateInstallments, setCanGenerateInstallments] = useState<boolean>(false)
    const [isTotalValueGreaterThanRemaining, setIsTotalValueGreaterThanRemaining] = useState<boolean>(false)

    const getPaymentConfigRequest = useRequest<IPaymentConfigSearchMostRelevantPartnerResponseDTO>()
    useEffect(onGetPaymentConfigRequestChange, [getPaymentConfigRequest.isAwaiting])

    useEffect(defineFieldsVisibility, [props.formStateManager])

    /**
     * Define visibilidade dos campos de acordo com a forma de pagamento escolhida.
     */
    function defineFieldsVisibility(): void {

        const formValues = props.formStateManager.getFormValues()
        if (!formValues)
            return

        setShouldShowTotalInstallments(false)
        setShouldShowFirstInstallmentDate(false)
        setCanGenerateInstallments(false)
        setShouldShowPaymentType(false)
        setShouldShowHasGracePeriod(false)
        setIsTotalValueGreaterThanRemaining(false)

        if (!formValues.paymentRule)
            return

        setShouldShowTotalInstallments((formValues.paymentRule as PaymentConfigRulesResponseDTO)?.rules?.enableInstallments)

        switch (formValues.paymentMethod) {

            case PaymentMethodEnum.CREDIT_CARD:
                setShouldShowPaymentType(true)
                setShouldShowFirstInstallmentDate(props.isEditingSaleCharges)
                break

            case PaymentMethodEnum.PIX_RECURRENT:
            case PaymentMethodEnum.CREDIT_CARD_RECURRENT:
            case PaymentMethodEnum.BANK_BILLET_RECURRENT:
                setShouldShowHasGracePeriod(true)
                setShouldShowFirstInstallmentDate(props.isEditingSaleCharges)
                break

            case PaymentMethodEnum.MONEY:
            case PaymentMethodEnum.BANK_BILLET:
            case PaymentMethodEnum.CHECK:
            case PaymentMethodEnum.PAYMENT_BOOK:
                setShouldShowFirstInstallmentDate(true)
                break

            case PaymentMethodEnum.DEBIT:
            case PaymentMethodEnum.PIX:
            case PaymentMethodEnum.DEPOSIT:
            default:
                break
        }

        const totalInstallments = formValues.totalInstallments
        if (totalInstallments <= 1)
            setShouldShowFirstInstallmentDate(false)

        let isGreaterThanRemaing = false
        if (!!formValues.totalValue) {
            // Se estiver editando uma configuracao de cobranca, devemos tirar do valor total inserido o somatorio das parcelas, pois o restante ja esta considerando o que foi inserido antes
            // Caso seja a criacao, apenas verifica se o total inserido eh maior do que falta

            const totalAux: number = !!props.initialCharge
                ? (MoneyUtils.getNumber(props.formStateManager.getFieldValue('totalValue')) - CreateChargeUtils.getInstallmentsSum(props.initialCharge.installments))
                : MoneyUtils.getNumber(props.formStateManager.getFieldValue('totalValue'))

            // Considera a tolerancia de dizima
            isGreaterThanRemaing = MoneyUtils.getNumber(totalAux) > MoneyUtils.getNumber(props.totalRemainingValue)
            setIsTotalValueGreaterThanRemaining(isGreaterThanRemaing)
        }

        if (!!formValues.paymentMethod && !!formValues.totalInstallments && !!formValues.totalValue)
            setCanGenerateInstallments(!isGreaterThanRemaing)
    }

    /**
     * Ao clicar para gerar parcelas
     */
    function generateInstallments(): void {

        if (!canGenerateInstallments) {
            console.log('Nem todos os atributos foram definidos para gerar as parcelas')
            return
        }

        const paymentRule = props.formStateManager.getFieldValue('paymentRule')
        if (!paymentRule) {
            NotificationHelper.error('Ops', 'Parceio de Pagamento não selecionado')
            return
        }

        props.onGenerateInstallments(paymentRule)
    }

    /**
     * Retorno da requisicao para pegar agenda passada como parametro
     */
    function onGetPaymentConfigRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getPaymentConfigRequest, 'Erro obter configurações para a forma de pagamento informada'))
            return

    }

    return (
        <WrapperSCP>
            <SelectPaymentConfigCP
                formStateManager={props.formStateManager}
                paymentMethodFieldName={'paymentMethod'}
                paymentAccountFieldName={'paymentAccountCode'}
                disabled={!!props.initialCharge?.code}
                onSelectPaymentPartner={(paymentPartner) => props.formStateManager.changeFieldValue('paymentRule', paymentPartner)}
            />

            <RowCP>
                <ColumnCP size={6}>
                    <InputCP
                        label={'Valor Total'}
                        mask={InputMaskTypeEnum.MONEY}
                        required={true}
                        formStateManager={props.formStateManager}
                        fieldName={'totalValue'}
                        disabled={!!props.initialCharge?.code}
                    />
                </ColumnCP>
                <ConditionalRenderCP shouldRender={shouldShowTotalInstallments}>
                    <ColumnCP size={6}>
                        <InputCP
                            label={'Parcelas'}
                            type={'number'}
                            required={true}
                            min={1}
                            formStateManager={props.formStateManager}
                            fieldName={'totalInstallments'}
                            disabled={!!props.initialCharge?.code}
                        />
                    </ColumnCP>
                </ConditionalRenderCP>
                <ConditionalRenderCP shouldRender={shouldShowTotalInstallments}>
                    <ColumnCP size={4}>
                        <InputCP
                            label={'Juros(%)'}
                            type={'number'}
                            required={false}
                            formStateManager={props.formStateManager}
                            fieldName={'juros'}
                            disabled={!!props.initialCharge?.code}
                        />
                    </ColumnCP>
                </ConditionalRenderCP>

                <ConditionalRenderCP shouldRender={shouldShowFirstInstallmentDate}>
                    <ColumnCP size={8}>
                        <InputCP
                            type={'date'}
                            label={'Data 1ª parcela'}
                            formStateManager={props.formStateManager}
                            fieldName={'firstInstallmentDate'}
                            disabled={!!props.initialCharge?.code}
                        />
                    </ColumnCP>
                </ConditionalRenderCP>
                <ConditionalRenderCP shouldRender={shouldShowPaymentType}>
                    <ColumnCP size={8}>
                        <SelectPaymentTypeCP
                            label={'Maquinha ou Link?'}
                            required={true}
                            formStateManager={props.formStateManager}
                            fieldName={'paymentType'}
                            disabled={!!props.initialCharge?.code}
                        />
                    </ColumnCP>
                </ConditionalRenderCP>
                <ConditionalRenderCP shouldRender={shouldShowHasGracePeriod}>
                    <ColumnCP size={12}>
                        <SwitchCP
                            textOutside={'Carência na 1ª parc.?'}
                            isTextInside={false}
                            textOutsidePosition={'top'}
                            formStateManager={props.formStateManager}
                            fieldName={'hasGracePeriod'}
                            marginTop={0}
                            disabled={!!props.initialCharge?.code}
                        />
                    </ColumnCP>
                </ConditionalRenderCP>
            </RowCP>

            <AlertCP
                message={'O valor desta forma de pagamento está maior do que o valor restante para fechar a venda.'}
                type={'error'}
                show={isTotalValueGreaterThanRemaining}
            />

            <ConditionalRenderCP shouldRender={!props.initialCharge?.code}>
                <FlexCP justifyContent={'center'} marginTop={10}>
                    <ButtonCP
                        onClick={generateInstallments}
                        type={'ghost'}
                        disabled={!canGenerateInstallments}
                        loading={getPaymentConfigRequest.isAwaiting}
                    >
                        Gerar Parcelas
                    </ButtonCP>
                </FlexCP>
            </ConditionalRenderCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
