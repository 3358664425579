import { GenericFormValidator } from 'main/common/form-state-manager/GenericFormValidator'
import { IVoucherResponseDTO } from 'main/modules/sale/services/voucher/dtos/response/IVoucherResponseDTO'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { ISaleCharge } from 'main/modules/sale/components/table-sale-charges/inner/ISaleCharge'
import { IsOptional } from 'class-validator/decorator/decorators'

export class SalePaymentDataFormModel extends GenericFormValidator {

    @IsNotEmpty()
    originalTotalValue: number

    @IsOptional()
    discountValue?: string

    @IsOptional()
    valorJuro?:number

    @IsNotEmpty()
    totalValue: number

    @IsNotEmpty()
    saleCharges: ISaleCharge[]

    @IsOptional()
    voucherCode?: number

    @IsOptional()
    voucher?: IVoucherResponseDTO

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }
}
