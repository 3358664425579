import React from 'react'
import { AccessControlCP } from 'main/common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { StringUtils } from 'main/common/utils/StringUtils'
import { RouteConfigTP } from 'main/common/types/RouteConfigTP'
import { ScreenReports } from 'main/modules/reports/screens/screen-reports/ScreenReports'

ReportsRoutes.ROOT = 'relatorios'

ReportsRoutes.INDICATORS_REPORTS_ROOT = `${ReportsRoutes.ROOT}/indicadores`
ReportsRoutes.FUNNEL_INDICATOR_REPORT = `${ReportsRoutes.INDICATORS_REPORTS_ROOT}/pipeline-vendas`
ReportsRoutes.NPS_REPORT = `${ReportsRoutes.INDICATORS_REPORTS_ROOT}/nps`

ReportsRoutes.SALE_REPORTS_ROOT = `${ReportsRoutes.ROOT}/venda`
ReportsRoutes.SALE_ANALYSIS_REPORT = `${ReportsRoutes.SALE_REPORTS_ROOT}/analise-vendas`
ReportsRoutes.SALE_SOURCE_REPORT = `${ReportsRoutes.SALE_REPORTS_ROOT}/origem-venda`
ReportsRoutes.COMISSOES_REPORT = `${ReportsRoutes.SALE_REPORTS_ROOT}/comissoes`
ReportsRoutes.VENDAS_POR_TRATAMENTO = `${ReportsRoutes.SALE_REPORTS_ROOT}/vendas-por-tratamento`
ReportsRoutes.SESSOES_RESP_REPORT = `${ReportsRoutes.SALE_REPORTS_ROOT}/sessoes-resp`

ReportsRoutes.SCHEDULER_REPORTS_ROOT = `${ReportsRoutes.ROOT}/agenda`
ReportsRoutes.SCHEDULER_LIST_REPORT = `${ReportsRoutes.SCHEDULER_REPORTS_ROOT}/agenda-do-dia`

ReportsRoutes.FINANCIAL_REPORTS_ROOT = `${ReportsRoutes.ROOT}/financeiro`
ReportsRoutes.FINANCIAL_DRE_REPORT = `${ReportsRoutes.FINANCIAL_REPORTS_ROOT}/dre`

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(ReportsRoutes.ROOT),
        path: ReportsRoutes.ROOT,
        component: ScreenReports,
    },
]

/**
 * Componente que define as rotas para acesso ao modulo de RELATORIOS
 */
export function ReportsRoutes(): JSX.Element {
    return (
        <AccessControlCP permissions={[PermissionEnum.ROLE_CRM]}>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}

