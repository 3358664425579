import { faBullhorn, faCalendarAlt, faChartLine, faCog, faFileInvoiceDollar, faFunnelDollar, faTachometerAlt, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { ModuleEnum } from 'main/common/enums/ModuleEnum'
import { AdminRoutes } from 'main/modules/admin/AdminRoutes'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { PermissionUtils } from 'main/modules/auth/utils/PermissionUtils'
import { DashboardRoutes } from 'main/modules/dashboard/routes/DashboardRoutes'
import { FinancialRoutes } from 'main/modules/financial/routes/FinancialRoutes'
import { MarketingRoutes } from 'main/modules/marketing/routes/MarketingRoutes'
import { PersonRoutes } from 'main/modules/people/routes/PersonRoutes'
import { SalesFunnelRoutes } from 'main/modules/sales-funnel/routes/SalesFunnelRoutes'
import { SchedulerRoutes } from 'main/modules/scheduler/routes/SchedulerRoutes'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { SegmentUtils } from 'main/modules/segment/utils/SegmentUtils'
import React from 'react'
import { ReportsRoutes } from 'main/modules/reports/routes/ReportsRoutes'
import { SalesFunnelSalesViewEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelSalesViewEnum'
import { SalesFunnelOpportunityViewEnum } from 'main/modules/sales-funnel/screens/screen-sales-funnel/inner/SalesFunnelOpportunityViewEnum'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { MenuItemTP } from 'submodules/nerit-framework-ui/common/components/menu/menu/inner/MenuItemTP'
import { faDev } from '@fortawesome/free-brands-svg-icons'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { PrivateRouter } from 'main/modules/app/routes/PrivateRouter'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'

/**
 * UTILITARIOS do menu principal do sistema
 */
export const UserMenuUtils = {

    getMenuOptionsTop(): MenuItemTP[] {
        return [
            {
                route: DashboardRoutes.ROOT,
                title: 'Dashboard | Painel de Indicadores',
                icon: <FontAwsomeIconCP type={faTachometerAlt} style={{ fontSize: '20px' }} color={ThemeProject.primaryMenuItemColor}/>,
                enabled: PermissionUtils.hasAccess(undefined, [PermissionEnum.ROLE_DASHBOARD_ATTENDANT, PermissionEnum.ROLE_DASHBOARD_CONSULTANT, PermissionEnum.ROLE_DASHBOARD_MANAGER])
            },
            {
                route: PersonRoutes.ROOT,
                title: 'Contatos',
                icon: <FontAwsomeIconCP type={faUsers} style={{ fontSize: '20px' }} color={ThemeProject.primaryMenuItemColor}/>,
                enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_CRM, [PermissionEnum.ROLE_CRM]),
                menuItemsChildren: [
                    {
                        route: `${PersonRoutes.ROOT}/${SegmentUtils.getSegmentTypeName(SegmentTypeEnum.CUSTOMER, true)}`,
                        title: SegmentUtils.getSegmentTypeName(SegmentTypeEnum.CUSTOMER),
                        enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_CRM, [PermissionEnum.ROLE_CRM]),
                        renderType: 'text',
                    },
                    {
                        route: `${PersonRoutes.ROOT}/${SegmentUtils.getSegmentTypeName(SegmentTypeEnum.COLLABORATOR, true)}`,
                        title: SegmentUtils.getSegmentTypeName(SegmentTypeEnum.COLLABORATOR),
                        enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_CRM, [PermissionEnum.ROLE_ADMIN]),
                        renderType: 'text',
                    },
                    {
                        route: `${PersonRoutes.ROOT}/${SegmentUtils.getSegmentTypeName(SegmentTypeEnum.SUPPLIER, true)}`,
                        title: SegmentUtils.getSegmentTypeName(SegmentTypeEnum.SUPPLIER),
                        enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_CRM, [PermissionEnum.ROLE_CRM]),
                        renderType: 'text',
                    },
                ]
            },
            {
                route: `${SalesFunnelRoutes.ROOT}/${SalesFunnelOpportunityViewEnum.LEAD_TODAY}`,
                title: 'Funil de vendas',
                icon: <FontAwsomeIconCP type={faFunnelDollar} style={{ fontSize: '20px' }} color={ThemeProject.primaryMenuItemColor}/>,
                enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_CRM, [PermissionEnum.ROLE_CRM], 'franchise'),
                menuItemsChildren: [
                    {
                        route: `${SalesFunnelRoutes.ROOT}/${SalesFunnelOpportunityViewEnum.LEAD_TODAY}`,
                        title: 'Leads',
                        enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_CRM, [PermissionEnum.ROLE_CRM]),
                        renderType: 'text',
                    },
                    {
                        route: `${SalesFunnelRoutes.ROOT}/${SalesFunnelOpportunityViewEnum.EVALUATION_TODAY}`,
                        title: 'Avaliações',
                        enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_CRM, [PermissionEnum.ROLE_CRM]),
                        renderType: 'text',
                    },
                    {
                        route: `${SalesFunnelRoutes.ROOT}/${SalesFunnelOpportunityViewEnum.FOLLOWUP_TODAY}`,
                        title: 'Follow-up',
                        enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_CRM, [PermissionEnum.ROLE_CRM]),
                        renderType: 'text',
                    },
                    {
                        route: `${SalesFunnelRoutes.ROOT}/${SalesFunnelSalesViewEnum.SALES_TODAY}`,
                        title: 'Vendas',
                        enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_CRM, [PermissionEnum.ROLE_CRM]),
                        renderType: 'text',
                    },
                ]
            },
            {
                route: SchedulerRoutes.CALENDAR,
                title: 'Agenda',
                icon: <FontAwsomeIconCP type={faCalendarAlt} style={{ fontSize: '20px' }} color={ThemeProject.primaryMenuItemColor}/>,
                enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_SCHEDULER, null, 'franchise'),
                menuItemsChildren: [
                    {
                        route: SchedulerRoutes.CALENDAR,
                        title: 'Agenda da Clínica',
                        renderType: 'text',
                    },
                    {
                        route: SchedulerRoutes.SCHEDULER,
                        title: 'Marcação de Sessão',
                        renderType: 'text',
                    },
                    {
                        route: SchedulerRoutes.ATTENDANCE_QUEUE,
                        title: 'Fila de Atendimento',
                        renderType: 'text',
                        enabled: PermissionUtils.isModuleAvailable(ModuleEnum.MODULE_LISOLASER),
                    },
                ]
            },
            {
                route: FinancialRoutes.ROOT,
                title: 'Financeiro',
                icon: <FontAwsomeIconCP type={faFileInvoiceDollar} style={{ fontSize: '20px' }} color={ThemeProject.primaryMenuItemColor}/>,
                enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_FINANCIAL, [PermissionEnum.ROLE_FINANCIAL], 'franchise')
            },
            {
                route: MarketingRoutes.MAIN_SCREEN_ROOT,
                title: 'Marketing',
                icon: <FontAwsomeIconCP type={faBullhorn} style={{ fontSize: '20px' }} color={ThemeProject.primaryMenuItemColor}/>,
                enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_MARKETING, [PermissionEnum.ROLE_MARKETING])
            },
            {
                route: ReportsRoutes.ROOT,
                title: 'Relatórios',
                icon: <FontAwsomeIconCP type={faChartLine} style={{ fontSize: '20px' }} color={ThemeProject.primaryMenuItemColor}/>,
                enabled: PermissionUtils.hasAccess(ModuleEnum.MODULE_CRM, [PermissionEnum.ROLE_CRM])
            },
        ]
    },

    getMenuOptionsBottom(): MenuItemTP[] {
        const somenteHorarios = !!AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.ROLE_HORARIO_TRABALHO);
        return [
            {
                route: PrivateRouter.DEV_TEST,
                title: 'Dev',
                icon: <FontAwsomeIconCP type={faDev} color={ThemeProject.primaryMenuItemColor} style={{ fontSize: '20px' }}/>,
                enabled: NeritFrameworkProjectConfig.isDev(),
                menuItemsChildren: [
                    {
                        title: 'Links Públicos',
                        route: `${PrivateRouter.DEV_TEST}?publicLinks=true`,
                        renderType: 'text',
                    },
                ]
            },
            {
                route: AdminRoutes.SYSTEM_ACCOUNT,
                title: 'Configurações do sistema',
                icon: <FontAwsomeIconCP type={faCog} style={{ fontSize: '20px' }} color={ThemeProject.primaryMenuItemColor}/>,
                enabled: PermissionUtils.hasAccess(null, [PermissionEnum.ROLE_ADMIN]) && !somenteHorarios
            },
        ]
    },

}
