import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { FranchiseRequests } from 'main/modules/admin/services/franchise/FranchiseRequests'
import { CardCP } from 'main/common/components/card/CardCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { TargetConfigTP } from 'main/modules/auth/services/dtos/types/TargetConfigTP'
import { IFranchiseConfigResponseDTO } from 'main/modules/admin/services/franchise/dtos/response/IFranchiseConfigResponseDTO'
import { FranchiseTargetConfigFieldsICP } from 'main/modules/admin/screens/screen-admin/content/screen-content-franchise-target-config/inner/FranchiseTargetConfigFieldsICP'
import { MetaFinanceira } from './inner/MetaFinanceira'
import { ExcelUtil } from "main/modules/people/utils/ExcelUtil";

export function ScreenContentFranchiseTargetConfigCP(): JSX.Element {

    const [targetsConfig, setTargetsConfig] = useState<TargetConfigTP>()
    const [listaUnidades, setListaUnidades] = useState<any>(null);

    const getFranchiseConfigRequest = useRequest<IFranchiseConfigResponseDTO>()
    const getAllConfigRequest = useRequest<any>();
    const atualizarConfisRequest = useRequest<any>();

    useEffect(onGetFranchiseConfigRequestChange, [getFranchiseConfigRequest.isAwaiting])
    useEffect(recebeuConfigs,[getAllConfigRequest.isAwaiting]);
    useEffect(onAtualizou,[atualizarConfisRequest.isAwaiting]);

    const updateFranchiseConfigRequest = useRequest<'none'>()
    useEffect(onUpdateFranchiseConfigRequestChange, [updateFranchiseConfigRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa
     */
    function init(): void {
        getFranchiseConfigRequest.runRequest(FranchiseRequests.getFranchiseConfig())
        getAllConfigRequest.runRequest(FranchiseRequests.getAllFranchiseConfig())
    }

    function onAtualizou(){
        if (!RequestUtils.isValidRequestReturn(atualizarConfisRequest, 'Erro ao buscar configurações'))
            return
        NotificationHelper.success('Certinho', 'Metas financeiras atualizadas com sucesso.')
        getAllConfigRequest.runRequest(FranchiseRequests.getAllFranchiseConfig())
    }

    function recebeuConfigs(){
        if (!RequestUtils.isValidRequestReturn(getAllConfigRequest, 'Erro ao buscar configurações'))
            return
        console.log("Recebido ",getAllConfigRequest.responseData )
        if(getAllConfigRequest.responseData['list'] && getAllConfigRequest.responseData.list.length){
            const r = ExcelUtil.ordenar(getAllConfigRequest.responseData.list,'fantasyName').filter((i:any)=>!!i?.config?.parameters?.targetsConfig?.sale);
            setListaUnidades(r);
        }
    }

    async function salvarFinanceiro(){
        const alvos = listaUnidades.filter((i:any)=>!!i?.config?.parameters?.targetsConfig?.sale).map((i:any)=>{
            return {
                codigo:i.config.code,
                parametros:i.config.parameters,
                valor:parseFloat(i?.config?.parameters?.targetsConfig?.sale?.monthlySalesRevenue) || 0,
                nome:i.socialName
            }
        })
        console.log("Salvando Metas ", {alvos:alvos});
        const carga = {alvos:alvos}
        atualizarConfisRequest.runRequest(FranchiseRequests.atualizarConfiguracoes(carga));
    }

    /**
     * Retorno ao obter horarios.
     */
    function onGetFranchiseConfigRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getFranchiseConfigRequest, 'Erro ao buscar configurações'))
            return

        setTargetsConfig(getFranchiseConfigRequest.responseData!.parameters.targetsConfig)
    }

    /**
     * Atualiza dados.
     */
    function updateTargetsConfig(): void {

        if (!targetsConfig)
            return

        // Pega todos os paramentos do que foi carrega
        const allParams = getFranchiseConfigRequest.responseData?.parameters
        if (!allParams)
            return

        // Altera o target para o atual
        allParams.targetsConfig = targetsConfig

        updateFranchiseConfigRequest.runRequest(FranchiseRequests.updateFranchiseConfig({ parameters: allParams }))
    }

    /**
     * Retorno da API ao salvar horario de funcionamento.
     */
    function onUpdateFranchiseConfigRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(updateFranchiseConfigRequest, 'Erro ao salvar horário de funcionamento'))
            return

        NotificationHelper.success('Pronto', 'Metas atualizadas com sucesso.')
        init()
    }

    return (
        <Wrapper>
            <ContentWrapper>

                <CardCP isLoading={getFranchiseConfigRequest.isAwaiting}>
                    <FranchiseTargetConfigFieldsICP fields={targetsConfig}/>
                </CardCP>

                <FlexCP justifyContent={'flex-end'} marginTop={20}>
                    <ButtonCP
                        onClick={updateTargetsConfig}
                        type={'primary'}
                        marginRight={20}
                        loading={updateFranchiseConfigRequest.isAwaiting}
                    >
                        Salvar
                    </ButtonCP>
                </FlexCP>
            </ContentWrapper>
            <ContentWrapper>
                <CardCP isLoading={getAllConfigRequest.isAwaiting}>
                    
                    <MetaFinanceira
                        lista={listaUnidades}
                    />
                    <BotaoWrapper>
                        <ButtonCP
                            onClick={salvarFinanceiro}
                            type={'primary'}
                            loading={atualizarConfisRequest.isAwaiting}
                        >
                            Salvar Metas Financeiras
                        </ButtonCP>
                    </BotaoWrapper>
                </CardCP>
            </ContentWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`

const ContentWrapper = styled.div`
  width: 400px;
`

const BotaoWrapper = styled.div`
  display:flex;
  justify-content:flex-end;
`
