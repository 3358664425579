import { TextCP } from 'main/common/components/text/TextCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { BankAccountRequests } from 'main/modules/financial/services/bank-account/BankAccountRequests'
import React, { useEffect, useState } from 'react'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'

interface ITotalAccountBalanceCPProps {
    shouldReloadBalance: boolean
    onBalanceLoaded: () => void
}

/**
 * Componente que exibe o saldo total em conta da empresa
 */
export function TotalAccountBalanceCP(props: ITotalAccountBalanceCPProps): JSX.Element {

    useEffect(init, [props.shouldReloadBalance])

    const [balance, setBalance] = useState<number>(0)

    const invoicesBalanceRequest = useRequest('none')
    useEffect(() => onRequestChange(), [invoicesBalanceRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.shouldReloadBalance)
            return

        invoicesBalanceRequest.runRequest(BankAccountRequests.balanceConfig())
    }

    /**
     * Retorno da API com o saldo
     */
    function onRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(invoicesBalanceRequest, 'Erro ao buscar saldo total'))
            return
        
        setBalance((invoicesBalanceRequest.responseData as any).data)
        props.onBalanceLoaded()
        
    }

    if(AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.VER_SOMENTE_RECEITAS_ATRASADAS)){
        return (
            <TextCP
                loading={invoicesBalanceRequest.isAwaiting}
                size={'large'}
                color={'#FFF'}
                text={`Saldo em conta: - `}
            />
        )
    }

    return (
        <TextCP
            loading={invoicesBalanceRequest.isAwaiting}
            size={'large'}
            color={'#FFF'}
            text={`Saldo em conta: ${MaskUtils.applyMoneyMask(balance)}`}
        />
    )
}
