import { TextCP } from 'main/common/components/text/TextCP'
import styled from 'main/config/theme/styledWithTheme'
import { GenderEnum, GenderPTEnum } from 'main/modules/people/enums/GenderEnum'
import React from 'react'

interface IPersonalInfoICPProps {
    rg?: string
    gender?: GenderEnum
    birthDate?: string
}

export function PersonalInfoICP(props: IPersonalInfoICPProps): JSX.Element {
    return (
        <WrapperSCP>
            <TextCP text={props.rg ? `RG: ${props.rg}` : 'RG: Não informado'} style={{'maxWidth':'40px'}}/>
            <TextCP text={props.gender ? `Gênero: ${GenderPTEnum[props.gender]}` : 'Gênero: Não informado'}/>
            <TextCP text={props.birthDate ? `Data de Nascimento: ${props.birthDate}` : 'Data de Nascimento: Não informada'}/>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
   
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
    &::after {
        content: '';
        height: 50px;
        border-radius: 0 10px 10px 0;
        width: 4px;
        background: ${props => props.theme.primaryColor};
        position: absolute;
        top: 0;
        left: -24px;
    }

`