import { HttpMethodEnum } from "main/common/enums/HttpMethodEnum";
import { RequestHelper } from "main/common/request-manager/RequestHelper";
import { AppStateUtils } from "main/common/utils/AppStateUtils";
import { SystemConfig } from "main/config/SystemConfig";
import React, { useEffect, useState } from "react"
import * as One from "common/utils/One"
import { RowCP } from "main/common/components/grid/RowCP";
import { ColumnCP } from "main/common/components/grid/ColumnCP";
import { IDateRangeFilter } from "main/common/components/date-range-picker/inner/IDateRangeFilter";
import moment from "moment";
import { DateRangePickerCP } from "main/common/components/date-range-picker/DateRangePickerCP";
import styled from "styled-components";
import { TableCP } from "main/common/components/table/TableCP";
import { CardCP } from "main/common/components/card/CardCP";
import { ButtonCP } from "main/common/components/button/ButtonCP";
import { notification } from 'antd';
import { ExcelUtil } from "main/modules/people/utils/ExcelUtil";
import { ChartPieCP, PieChartSeriesTP } from "main/common/components/chart/ChartPieCP";


notification.config({
    placement: 'bottomRight',
  });


const colunas = [
    {
      title: 'Tratamento',
      dataIndex: 'trt_name',
      key: 'trt_name',
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantidade',
      key: 'quantidade',
    }
  ];

  //@ts-ignore
  let series:PieChartSeriesTP[] = [];



export function RelatorioTratamento():JSX.Element{
    const [dateFilters, setDateFilters] = useState<IDateRangeFilter>({
        beginDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate()
    })
    const [dados,setDados] = useState(null);
    const [semDados, setSemDados] = useState(true);
    const [carregando, setCarregando] = useState(false);
    const franquia = AppStateUtils.getCurrentFranchise();
    
    useEffect(()=>{carregarDados();},[dateFilters]);
    

    function mudouDatas(n:any){
        setDateFilters(n);
    }

    

    function processarResultado(dados:any){
        console.log("DADOS RECEBIDOS ", dados);
        series = dados.map((i:any)=>{
            return {
                id:i.trt_name+i.quantidade,
                label:i.trt_name,
                value:i.quantidade
            }
        })
        setDados(dados);
       
    }

    

    function exportarCSV(){
        if(!dados ){
            notification.open({
                message: 'Sem Dados',
                description: (
                  <div>
                    <b>Não há dados para serem exportados.</b>
                    
                  </div>
                ),
                duration: 3,
              });
            return
        }
       
        let r = `VENDAS POR TRATAMENTO\n `;
        if(dados){
            r+= ExcelUtil.excel(dados,[['TRATAMENTO','trt_name'],['Quantidade','quantidade']]);
            r+=`\n`;
        }
        
        
        ExcelUtil.salvarTexto('comissoes.CSV',r);
    }

    

    async function carregarDados(){
        setSemDados(true);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/dashboard/vendas-por-tratamento`,
            method: HttpMethodEnum.POST,
            params: {
                de:One.dateParaSQL(dateFilters?.beginDate),
                ate:One.dateParaSQL(dateFilters?.endDate),
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                schema:AppStateUtils.getDomainSlug()
            }
           
        }).finally(()=>setCarregando(false)); 

        if(One.ok(resultado)){
            processarResultado(One.dados(resultado));
            setSemDados(false);
            return;
        }
        setSemDados(false);
    }

    function BlocoVenda(){
        if(!dados)return <></>
        return (
            <>
            <TableCP
            columns={colunas}
            data={dados ?? []}
            loading={semDados}
            />
        </>
        )
    }

    
    

    return (
        <AreaFormatada>
             <CardCP
            innerSpacing={'none'}
            overflow={'auto'}
            
            >
                <div>
                    <div className="titulo-franquia">
                        <div>Unidade</div>
                        {franquia?.code} - {franquia?.name}
                    </div>
                    {carregando?'Carregando... ':''}
                </div>
                

            </CardCP>
            <RowCP spaceBetweenCols={20} flex={true}  justify="end">
                    <ButtonCP
                            icon={'table'}
                            size={'small'}
                            type={'primary'}
                            tooltip={'Clique para exportar em excel'}
                            onClick={() => exportarCSV()}
                        >
                            Exportar
                    </ButtonCP>
                </RowCP>
            <RowCP spaceBetweenCols={20} align={'middle'}>

                <ColumnCP size={8}>
                    <DateRangePickerCP
                        label={'Intervalo de Tempo'}
                        value={dateFilters}
                        onChange={mudouDatas}
                        fastFilter={'all'}
                        allowClear={false}
                        showBorder={true}
                    />
                   
                </ColumnCP>
                
                
                
            </RowCP>
            <CardCP
            innerSpacing={'normal'}
            
            >
                
            <section className="tabela-grafico">
                <BlocoVenda/>
                <div className="area-do-grafico">
                    <ChartPieCP series={series} loading={semDados} height={500} width={500}></ChartPieCP>
                </div>
            </section>
                
            </CardCP>
        </AreaFormatada>
    )
}


const AreaFormatada = styled.div`
    .titulo-franquia{
        text-align:center;
        font-weight: 645;
        font-size: 20px;
        letter-spacing: -0.048em;
    }

    .tabela-grafico{
        display:flex;
        gap:4px;
        align-items:center;
    }

    .area-do-grafico{
        width:550px;
    }

    .indicador{
        display:flex;
        justify-content:flex-end;
    }

    .seletor-agente{
        position: relative;
        top: 14px;
    }

    .item-indicador{
        max-width: 421px;
        width: 300px;
    }
    
`