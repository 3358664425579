import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { IsOptional } from 'class-validator/decorator/decorators'
import { PaymentMethodEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentMethodEnum'
import { FormModel } from 'main/common/form-state-manager/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { PaymentConfigRulesResponseDTO } from 'submodules/neritclin-sdk/services/sale/paymentconfig/dtos/responses/inner/PaymentConfigRulesResponseDTO'
import { PaymentTypeEnum } from 'submodules/neritclin-sdk/services/sale/paymentconfig/enums/PaymentTypeEnum'

export class CreateChargeFormModel extends FormModel {

    @IsRequired()
    paymentAccountCode: number

    @IsNotEmpty()
    paymentMethod: PaymentMethodEnum

    @IsNotEmpty()
    totalValue: string

    @IsOptional()
    firstInstallmentDate?: Date

    @IsOptional()
    totalInstallments?: number

    @IsOptional()
    hasGracePeriod?: boolean

    @IsOptional()
    juros?: boolean

    @IsOptional()
    paymentType?: PaymentTypeEnum

    // Auxiliares
    paymentRule?: PaymentConfigRulesResponseDTO

    constructor(dto?: any) {
        super(dto)
        Object.assign(this, dto)
    }
}
