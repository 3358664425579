import * as _ from 'lodash'
import { FullCalendarCP, FullCalendarViewTP } from 'main/common/components/full-calendar/FullCalendarCP'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { IFullCalendarEvent } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEvent'
import { IAttendantDisplay } from 'main/modules/scheduler/interfaces/IAttendantDisplay'
import { IFullCalendarDateInfo } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarDateInfo'
import { IFullCalendarEventInfo } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEventInfo'
import { IFullCalendarEventDropInfo } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEventDropInfo'
import { IDateRangeFilter } from 'main/common/components/date-range-picker/inner/IDateRangeFilter'
import { IFullCalendarExtendedProps } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarExtendedProps'
import { renderToString } from 'react-dom/server'
import { IFullCalendarEventResize } from 'main/common/components/full-calendar/inner/interfaces/IFullCalendarEventResize'

interface ICalendarClinicCPProps {
    selectedDate?: Date | IDateRangeFilter
    events: IFullCalendarEvent[]
    defaultView: 'timeGridDay' | 'timeGridWeek' | 'resourceTimeGridDay'
    hideHeader?: boolean
    resources?: IAttendantDisplay[]
    onDateClick?: (dateInfo: IFullCalendarDateInfo) => void
    onEventClick?: (eventInfo: IFullCalendarEventInfo) => void
    onEventDrop?: (eventDropInfo: IFullCalendarEventDropInfo) => void
    onDateRangeChange?: (dateRange: IDateRangeFilter) => void
    onEventResize?: (eventResizeInfo: IFullCalendarEventResize) => void
    loading?: boolean
    slotLabelInterval?: string
    slotDuration?: string
    headerRight?: FullCalendarViewTP[]
}

/**
 * Wrapper do FullCalendar, considerando os atributos globais da clinica.
 */
export function CalendarClinicCP(props: ICalendarClinicCPProps): JSX.Element {

    const [startBusinessHour, setStartBusinessHour] = useState<string>()
    const [endBusinessHour, setEndBusinessHour] = useState<string>()

    useEffect(init, [props.selectedDate])

    /**
     * Chamado pela atualização dos dados da agenda (appointmentsList). Extrai deles os recursos e os eventos separadamente
     */
    function init(): void {

        if (!props.selectedDate || !(props.selectedDate instanceof Date)) {
            defineNoWorkingDay()
            return
        }

        // Define o horario inicio e final
        const businessHour = AppStateUtils.getCurrentFranchise()?.businessHours
        if (!businessHour) {
            defineNoWorkingDay()
            return
        }

        // Obtem o dia da data selecionada
        const businessHourOnDay = _.find(businessHour, { weekDay: props.selectedDate.getDay() })
        if (!businessHourOnDay) {
            defineNoWorkingDay()
            return
        }

        // Se chegou aqui temos configuracao horario de funcionamento da clinica no dia selecionado
        setStartBusinessHour(`${businessHourOnDay.beginTime}:00`)
        setEndBusinessHour(`${businessHourOnDay.endTime}:00`)
    }

    /**
     * Definir dias nao trabalhados.
     */
    function defineNoWorkingDay(): void {
        setStartBusinessHour(undefined)
        setEndBusinessHour(undefined)
    }

    return (
        <ContentWrapperSCP>
            <FullCalendarCP
                hideHeader={props.hideHeader ?? props.defaultView === 'resourceTimeGridDay'}
                headerRight={props.headerRight}
                events={props.events}
                resources={props.resources}
                selectedDate={props.selectedDate instanceof Date ? props.selectedDate : props.selectedDate?.beginDate}
                defaultView={props.defaultView}
                onDateClick={props.onDateClick}
                onEventClick={props.onEventClick}
                minTime={startBusinessHour}
                maxTime={endBusinessHour}
                canScheduleOverlap={!AppStateUtils.getCurrentFranchise()?.paramsConfig?.businessRulesConfig?.schedule?.shouldValidateOverlaps}
                onEventDrop={props.onEventDrop}
                loading={props.loading}
                onDateRangeChange={props.onDateRangeChange}
                slotLabelInterval={props.slotLabelInterval}
                slotDuration={props.slotDuration}
                onEventResize={props.onEventResize}
                showSlotDurationSelect={true}
                eventRender={(info) => {

                    const pronto = (info.event.extendedProps as IFullCalendarExtendedProps).sessoes?.some((s:any)=>s.status!=='CONCLUDED');

                    if(pronto)info.el.querySelector('.fc-time')!.innerHTML = info.el.querySelector('.fc-time')!.innerHTML +'<div title="Existem sessões não evoluídas">🏴</div>'
                    const titleExtra = (info.event.extendedProps as IFullCalendarExtendedProps).titleExtra
                    if (!!titleExtra)
                        info.el.querySelector('.fc-time')!.innerHTML = info.el.querySelector('.fc-time')!.innerHTML + renderToString(titleExtra)

                    const descriptionExtra = (info.event.extendedProps as IFullCalendarExtendedProps).descriptionExtra
                    if (!!descriptionExtra)
                        info.el.querySelector('.fc-title')!.innerHTML = info.el.querySelector('.fc-title')!.innerHTML + renderToString(descriptionExtra)
                }}
            />
        </ContentWrapperSCP>
    )
}

const ContentWrapperSCP = styled.div`
  .fc-content .fc-time {
    display: flex;
    justify-content: space-between;
  }
`
