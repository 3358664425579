import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { SwitchCP } from 'main/common/components/switch/SwitchCP'
import { SaleBasicDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SaleBasicDataFormModel'
import React, { useState } from 'react'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { AutocompletePersonCP } from 'main/modules/people/components/autocomplete-person/AutocompletePersonCP'
import { AutoCompleteOpportunitySourceCP } from 'main/modules/sales-funnel/components/autocomplete-opportunity-source/AutoCompleteOpportunitySourceCP'
import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { SelectSaleOriginCP } from 'main/modules/sale/components/select-sale-origin/SelectSaleOriginCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { RowCP } from 'main/common/components/grid/RowCP'

interface ISalesDataFormCPProps {
    formStateManager: IFormStateManager<SaleBasicDataFormModel>
    onSelectPayer?: (personCode: number) => void
}

export function SaleBasicDataFormICP(props: ISalesDataFormCPProps): JSX.Element {

    const [hasPayer, setHasPayer] = useState<boolean>(false)

    return (
        <>
            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={12}>
                    <AutocompletePersonCP
                        label={'Cliente'}
                        required={true}
                        fieldName={'personCustomerCode'}
                        formStateManager={props.formStateManager}
                        disabled={!!props.formStateManager.getFieldValue('personCustomer')}
                        initialOptions={props.formStateManager.getFieldValue('personCustomer') ? [props.formStateManager.getFieldValue('personCustomer')] : []}
                        considerCustomer={true}
                        onChange={(personCode) => props.formStateManager.changeFieldValue('personPayerCode', personCode)}
                    />
                </ColumnCP>
                <ColumnCP size={12}>
                    <InputCP
                        label={'Data da venda '}
                        type={'date'}
                        showTime={true}
                        required={true}
                        allowClear={false}
                        fieldName={'saleDate'}
                        value={props.formStateManager.getFieldValue('saleDate')}
                        formStateManager={props.formStateManager}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={12}>
                    <AutocompleteUserCP
                        label={'Vendedor'}
                        // onlyWithWorkSchedule={true}//somente carinhas com horário de trabalho setado
                        required={true}
                        fieldName={'sellerCode'}
                        formStateManager={props.formStateManager}
                        // initialOptions={props.formStateManager.getFieldValue('personSeller') ? [props.formStateManager.getFieldValue('personSeller')] : []}
                    />
                </ColumnCP>
                <ColumnCP size={12}>
                    <SelectSaleOriginCP
                        label={'Onde foi feita a venda?'}
                        required={true}
                        allowClear={false}
                        fieldName={'saleOrigin'}
                        formStateManager={props.formStateManager}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={12}>
                    <AutoCompleteOpportunitySourceCP
                        label={'Como o cliente ficou sabendo?'}
                        required={true}
                        fieldName={'sourceCode'}
                        sourcePersonLinkFieldName={'sourcePersonLinkCode'}
                        sourcePersonLinkInitialOption={props.formStateManager.getFieldValue('sourcePersonLink')}
                        formStateManager={props.formStateManager}
                        initialOptions={props.formStateManager.getFieldValue('source') ? [props.formStateManager.getFieldValue('source')] : []}
                        onSelectOpportunitySource={(source) => props.formStateManager.changeFieldValue('source', source)}
                        disableSourcePersonLink={false}
                    />
                </ColumnCP>
            </RowCP>

            <ConditionalRenderCP shouldRender={!!props.onSelectPayer}>
                <RowCP spaceBetweenCols={16}>
                    <ColumnCP size={12}>
                        <SwitchCP
                            isTextInside={false}
                            textInsideChecked={'Sim'}
                            textInsideUnchecked={'Não'}
                            onChange={(): void => setHasPayer(!hasPayer)}
                            isChecked={hasPayer}
                            textOutsidePosition={'top'}
                            textOutside={'A compra será paga por outra pessoa?'}
                        />
                    </ColumnCP>

                    <ColumnCP size={12}>
                        <ConditionalRenderCP shouldRender={hasPayer}>
                            <AutocompletePersonCP
                                label={'Responsável pelo pagamento'}
                                required={true}
                                fieldName={'personPayerCode'}
                                formStateManager={props.formStateManager}
                                initialOptions={props.formStateManager.getFieldValue('personCustomer') ? [props.formStateManager.getFieldValue('personCustomer')] : []}
                                considerCustomer={true}
                                segmentTypeToAdd={SegmentTypeEnum.CUSTOMER}
                                onChange={props.onSelectPayer}
                            />
                        </ConditionalRenderCP>
                    </ColumnCP>
                </RowCP>
            </ConditionalRenderCP>
        </>
    )
}
