import React from 'react'
import { ExcelUtil } from "main/modules/people/utils/ExcelUtil";
import { InputCP } from 'main/common/components/form-fields/input/InputCP';
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum';
import { styled } from 'main/config/theme/styledWithTheme'
import { MoneyUtils } from 'main/common/utils/MoneyUtils';

interface ICPProps {
    lista?: []
}

export function MetaFinanceira(props: ICPProps): JSX.Element {
    if(!props.lista || !props.lista.length) return <></>;

    return (
        <>
        {props.lista.map((i:any)=>(
            <div>
                <UnidadeWrapper>
                    {i.code+'-'+ExcelUtil.TEXTO(i.fantasyName)}
                </UnidadeWrapper>
            <InputCP label={'Meta de Vendas ($)'} mask={InputMaskTypeEnum.MONEY} value={i?.config?.parameters?.targetsConfig?.sale?.monthlySalesRevenue} onChange={(val) => {
                if(i?.config?.parameters?.targetsConfig?.sale){
                    i.config.parameters.targetsConfig.sale.monthlySalesRevenue = MoneyUtils.convertToFloat(val);
                }
            }}/>
            </div>

            ))}
        </>
    )


}

const UnidadeWrapper = styled.div`
    line-height: 0px;
    font-weight: 573;
    letter-spacing: -0.019em;
    font-size: 11px;
    color: black;
    margin-bottom: -3px;
`