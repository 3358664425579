import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { UserPermissionsDataICP } from 'main/modules/user/components/user-permission/inner/UserPermissionsDataICP'
import { IUserResponseDTO } from 'main/modules/user/services/dtos/response/IUserResponseDTO'
import { UserRequests } from 'main/modules/user/services/UserRequests'
import { IUserChangePermissionsRequestDTO } from 'main/modules/user/services/dtos/request/IUserChangePermissionsRequestDTO'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { UserFinancialPermissionsICP } from 'main/modules/user/components/user-permission/permissions/UserFinancialPermissionsICP'
import { UserSubPermissionsDataICP } from './inner/UserSubPermissionsDataICP'

interface IUserPermissionCPProps {
    onSave?: () => void
    userCode: number
}

/**
 * COMPONENTE Exibicao do form de permissoes de um usuário
 */
export function UserPermissionCP(props: IUserPermissionCPProps): JSX.Element {

    const [isActive, setIsActive] = useState<boolean>(false)
    const [permissionMap, setPermissionMap] = useState<Map<PermissionEnum, boolean>>(new Map())

    useEffect(initialize, [props.userCode])

    const updatePermissionsRequest = useRequest<void>(undefined, 'none')
    useEffect(onUpdatePermissionRequestChange, [updatePermissionsRequest.isAwaiting])

    const getUserRequest = useRequest<IUserResponseDTO>()
    useEffect(onGetUserRequestChange, [getUserRequest.isAwaiting])

    /**
     * Inicializa
     */
    function initialize(): void {

        const permMap = new Map()
        permMap.set(PermissionEnum.ROLE_ADMIN, false)
        permMap.set(PermissionEnum.ROLE_MARKETING, false)
        permMap.set(PermissionEnum.ROLE_SCHEDULER, false)
        permMap.set(PermissionEnum.ROLE_CRM, false)
        permMap.set(PermissionEnum.ROLE_DASHBOARD_ATTENDANT, false)
        permMap.set(PermissionEnum.ROLE_DASHBOARD_CONSULTANT, false)
        permMap.set(PermissionEnum.ROLE_DASHBOARD_MANAGER, false)
        permMap.set(PermissionEnum.ROLE_FINANCIAL, false)
        permMap.set(PermissionEnum.ROLE_CHANGE_PAYMENT_METHOD, false)
        permMap.set(PermissionEnum.ROLE_RETRY_CHARGE, false)
        permMap.set(PermissionEnum.ROLE_SALE_CANCELATION, false)
        permMap.set(PermissionEnum.ROLE_SALE_REFUND, false)

        setPermissionMap(permMap)

        getUserRequest.runRequest(UserRequests.getUserConfig(props.userCode))
    }

    /**
     * Retoro ao buscar os dados do usuario
     */
    function onGetUserRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getUserRequest, 'Erro obter permissoes do usuario logado'))
            return

        const returnUser = getUserRequest.responseData!

        // Status
        setIsActive(returnUser.active)

        // Define permissoes
        const permMap = new Map(permissionMap)

        _.each(returnUser.permissions, (currentPermission) => {
            permMap.set(currentPermission, true)
        })
        setPermissionMap(permMap)
    }

    /**
     * Ao mudar uma permissao para ativa ou nao.
     */
    function onChangePermission(checked: boolean, permission: PermissionEnum): void {

        const permMap = new Map(permissionMap)
        permMap.set(permission, checked)
        setPermissionMap(permMap)
    }

    /**
     * Atualiza as permissoes.
     */
    function updatePermissions(): void {

        const permissionList: PermissionEnum[] = []

        permissionMap.forEach((value, key) => {
            if (!!value)
                permissionList.push(key)
        })

        const dto: IUserChangePermissionsRequestDTO = {
            active: isActive,
            permissions: permissionList
        }

        updatePermissionsRequest.runRequest(UserRequests.changePermissionConfig(props.userCode, dto))
    }

    /**
     * Apos reotorno da api de atualizar permissoes
     */
    function onUpdatePermissionRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(updatePermissionsRequest, 'Ocorreu algum erro ao atualizar as permissões', true))
            return

        NotificationHelper.success('Pronto!', 'Permissões atualizadas com sucesso')
        if (props.onSave)
            props.onSave()
    }

    return (

        <WrapperSCP>

            <UserPermissionsDataICP
                isChecked={!!permissionMap.get(PermissionEnum.ROLE_ADMIN)}
                onChangeChecked={(checked) => onChangePermission(checked, PermissionEnum.ROLE_ADMIN)}
                title={'Administrador'}
                description={'Gerencia e configura todo o sistema, define horários de atendimento, permissões de membros da equipe, modelo de contrato, dados da empresa, etc...'}
            />
                <UserSubPermissionsDataICP
                    isChecked={!!permissionMap.get(PermissionEnum.ROLE_HORARIO_TRABALHO)}
                    onChangeChecked={(checked) => onChangePermission(checked, PermissionEnum.ROLE_HORARIO_TRABALHO)}
                    text={'Só pode alterar horário de trabalho na permissão de colaboradores.'}
                />
            <UserPermissionsDataICP
                isChecked={!!permissionMap.get(PermissionEnum.ROLE_SCHEDULER)}
                onChangeChecked={(checked) => onChangePermission(checked, PermissionEnum.ROLE_SCHEDULER)}
                title={'Agenda'}
                description={'Criar, editar agendamentos e evoluir sessões de atendimento.'}
            />
            <UserSubPermissionsDataICP
                isChecked={!!permissionMap.get(PermissionEnum.BLOQUEAR_HORARIOS)}
                onChangeChecked={(checked) => onChangePermission(checked, PermissionEnum.BLOQUEAR_HORARIOS)}
                text={'Pode bloquear e desbloquear horários na agenda.'}
                
            />
            <UserPermissionsDataICP
                isChecked={!!permissionMap.get(PermissionEnum.ROLE_CRM)}
                onChangeChecked={(checked) => onChangePermission(checked, PermissionEnum.ROLE_CRM)}
                title={'CRM'}
                description={'Acesso ao CRM, funil de vendas, contatos e capaz de lançar vendas no sistema.'}
            />

            <UserFinancialPermissionsICP
                onChangePermission={onChangePermission}
                permissions={permissionMap}
            />

            <UserPermissionsDataICP
                isChecked={!!permissionMap.get(PermissionEnum.ROLE_MARKETING)}
                onChangeChecked={(checked) => onChangePermission(checked, PermissionEnum.ROLE_MARKETING)}
                title={'Marketing'}
                description={'Acessa todo o módulo de marketing do sistema. Envia e-mails, sms e vê relatórios'}
            />
            <UserPermissionsDataICP
                isChecked={!!permissionMap.get(PermissionEnum.ROLE_DASHBOARD_ATTENDANT)}
                onChangeChecked={(checked) => onChangePermission(checked, PermissionEnum.ROLE_DASHBOARD_ATTENDANT)}
                title={'Dashboard de Técnica de Procedimentos'}
                description={'Dashboard para o perfil de técnica de procedimentos'}
            />
            <UserPermissionsDataICP
                isChecked={!!permissionMap.get(PermissionEnum.ROLE_DASHBOARD_CONSULTANT)}
                onChangeChecked={(checked) => onChangePermission(checked, PermissionEnum.ROLE_DASHBOARD_CONSULTANT)}
                title={'Dashboard de Avaliadora / Vendedora'}
                description={'Dashboard para o perfil de Avaliadora / Vendedora'}
            />
            <UserPermissionsDataICP
                isChecked={!!permissionMap.get(PermissionEnum.ROLE_DASHBOARD_MANAGER)}
                onChangeChecked={(checked) => onChangePermission(checked, PermissionEnum.ROLE_DASHBOARD_MANAGER)}
                title={'Dashboard de Gerente da Loja'}
                description={'Dashboard para o perfil de gestão da loja'}
            />
            <ButtonWrapperSCP>
                <ButtonCP
                    type={'primary'}
                    onClick={updatePermissions}
                    loading={updatePermissionsRequest.isAwaiting}
                >
                    Atualizar permissões
                </ButtonCP>
            </ButtonWrapperSCP>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    margin: 1em;
    min-height: 700px;
    display: grid;
    grid-template-columns: 1fr;
  .ant-row {
    margin-bottom: 15px;
  }

`
const ButtonWrapperSCP = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
min-width: 100%;
margin: 2em 0;
.ant-btn{
    margin-left: 1em;
}`
